// extracted by mini-css-extract-plugin
export const link = "Navbar-module--link--3bcnu";
export const active = "Navbar-module--active--3d3nV";
export const navbarMobileMenu = "Navbar-module--navbar-mobile-menu--1LHPl";
export const navbarMobileButton = "Navbar-module--navbar-mobile-button--2pgzh";
export const navbarMobileLine = "Navbar-module--navbar-mobile-line--175uP";
export const navbarMobileList = "Navbar-module--navbar-mobile-list--24tg_";
export const navbarMobileToggle = "Navbar-module--navbar-mobile-toggle--1Vg_U";
export const mobileNavLink = "Navbar-module--mobile-nav-link--1lJvB";
export const desktopLinks = "Navbar-module--desktop-links--2DHhy";
export const navbarMobile = "Navbar-module--navbar-mobile--3OBUU";
export const navLangMobile = "Navbar-module--nav-lang-mobile--1Emq4";
export const navLangDesktop = "Navbar-module--nav-lang-desktop--1nStm";